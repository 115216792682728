<template>
  <button :type="type" :class="['button-main', buttonWhite && 'white']">
    <slot />
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
    buttonWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-main {
  width: 100%;
  height: 36px;
  padding: 0 25px;
  background: $coffee;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 7px;
  color: #fff;
  font-weight: bold;

  &:hover {
    background: $darkCoffee;
  }

  &:active {
    background: $darkCoffee;
  }
}
.white {
  height: 36px;
  background: #e5e5e7;
  color: $mainColor;

  &:hover {
    background: #cdcdce;
  }

  &:active {
    background: #cdcdce;
  }
}
</style>
