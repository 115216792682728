<template>
  <div class="page-not-found">
    <div class="page-not-found__container">
      <div class="page-not-found__title">404</div>
      <div class="page-not-found__desc">Page Not Found</div>
      <div class="page-not-found__button-group">
        <MainButton @click.native="handleBackHome">BACK HOME</MainButton>
      </div>
    </div>
  </div>
</template>

<script>
import ROUTES from "~/constants/routes";

import MainButton from "~/components/atoms/buttons/MainButton.vue";
export default {
  name: "PageNotFound",
  components: {
    MainButton,
  },
  methods: {
    handleBackHome() {
      this.$router.push({
        path: ROUTES.HOME_PAGE,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  min-height: 100vh;
  display: flex;

  &__container {
    flex-grow: 1;
    margin-bottom: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    padding-bottom: 20px;
    font-size: 80px;
    font-weight: bold;
    color: $coffee;
  }

  &__desc {
    font-size: 20px;
    font-weight: bold;
  }

  &__button-group {
    padding-top: 20px;
  }
}
</style>
